export default {
    name: "oValidationNotification",

    data() {
      return {
        type: 'error',
        message: null,
        active: false
      }
    }
}
