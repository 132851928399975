export const StringHelper = {

    trimWithoutWordBreak: function (str, maxChars) {
      let trimmedStr = str;

      if(str.length > maxChars){
        trimmedStr = str.substr(0, maxChars);
        trimmedStr = trimmedStr.substr(0, Math.min(trimmedStr.length, trimmedStr.lastIndexOf(' '))) + '...';
      }

        return trimmedStr;
    },

    isNotEmpty: function(str) {
      let _isNotEmpty = false;

      if(str) {
        _isNotEmpty = (str.length > 0);
      }

      return _isNotEmpty;
    },

    isEmail: function(str) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
    }
};

export default StringHelper;
